import * as msTeams from '@microsoft/teams-js';
import { xhr } from '../helpers/xhr';

export class TokenService {
	private static instance: TokenService;

	public static getInstance(): TokenService {
		if (!TokenService.instance) {
			TokenService.instance = new TokenService();
		}
		return TokenService.instance;
	}

	public async getAuthenticationToken(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const authTokenRequest: msTeams.authentication.AuthTokenRequest = {
				successCallback: (token) => {
					resolve(token);
				},
				failureCallback: (error) => {
					reject(error);
				}
			};
			msTeams.authentication.getAuthToken(authTokenRequest);
		});
	}

	public async getAccessToken(scopes: string[]): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			xhr(`accesstoken`, { 
				method: 'POST',
				data: scopes
			})
			.then(response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
		});
	}
}
