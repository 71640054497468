import { xhr } from "../helpers/xhr";
import { IMemberModel, IMembersModel } from "../models/IMemberModel";

export class MemberService {
	private static currentMemberId: string = '';
	private static currentMemberUpn: string = '';

	public static async getMembers(committeeId: string, includeSignIn?: boolean): Promise<IMembersModel> {
		const response = await xhr(`committees/${committeeId}/members${includeSignIn?'?includeSignIn=true':''}`);
		var members = MemberService.parseArray(response.data.members);
		members.forEach(m => m.displayName = `${m.company?`[${m.company}] `:''}${m.familyName}, ${m.firstName}${m.title?`, ${m.title}`:''}`);
		members.sort((m1,m2)=>{ return `${m1.displayName}`.localeCompare(`${m2.displayName}`);})
		return {
			members: members,
			permissions: response.data.permissions
		};
	}

	public static async reinviteMembers(committeeId: string): Promise<void> {
		await xhr(`committees/${committeeId}/members/reinvite`);
	}

	public static parse(data: any): IMemberModel {
		return {
			id: data['id'],
			eMailAddress: data['eMailAddress'],
			title: data['title'],
			firstName: data['firstName'],
			familyName: data['familyName'],
			company: data['company'],
			division: data['division'],
			role: data['role'],	
			lastLogin: data['lastLogin'],
			displayName: data['displayName'],
			technicalStatus: data['technicalStatus'],
			isGuest: data['isGuest'],
			userId: data['userId']
		} as IMemberModel;
	}

	public static parseArray(data: any[]): IMemberModel[] {
		if (data && data.length) {
			return data.map(MemberService.parse);
		}
		return [];
	}

	public static setCurrentMember(id: string, upn: string|undefined) {
		this.currentMemberId = id;
		this.currentMemberUpn = upn?upn:'';
	}

	public static getCurrentMember() {
		return {
			id: this.currentMemberId,
			upn: this.currentMemberUpn
		};
	}
}