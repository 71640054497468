import styles from './CommitteeMemberCard.module.scss';
import { Person, PersonViewType, PersonCardInteraction } from '@microsoft/mgt-react';

import { IMemberModel } from '../models/IMemberModel';
import { TeamsThemeHelper } from '../helpers';
import { useState } from 'react';
import { useEffect } from 'react';

interface ICommitteeMemberCardProps {
    committeeMember: IMemberModel;
    disablePersonCard?: boolean;
}

export const CommitteeMemberCard = (props: ICommitteeMemberCardProps) => {
    const [mgtClass, setMgtClass] = useState('mgt-light');

    useEffect(() => {
        setMGTStylingClassName();
    });

    const setMGTStylingClassName = function(){
        const themeName = TeamsThemeHelper.getThemeString();
        switch(themeName) {
            case 'dark':
                setMgtClass("mgt-dark");
                break;
            case 'contrast':
                setMgtClass("mgt-dark");
                break;
            case 'light':
            default:
                setMgtClass("mgt-light");
                break;
        }
    }

    return (
        <Person
            className={[styles.memberCardContainer, mgtClass].join(' ')}
            userId={props.committeeMember?.userId}
            view={props.committeeMember?.isGuest?PersonViewType.oneline:PersonViewType.twolines}
            personCardInteraction={props.disablePersonCard?PersonCardInteraction.none:PersonCardInteraction.hover}
        >
        </Person>
    );
}