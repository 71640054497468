import styles from './MemberList.module.scss';

import { Flex, Text, Table, Button, Dialog } from '@fluentui/react-northstar';
import { IMembersModel, TechnicalMemberStatus } from '../models/IMemberModel';
import { ICommitteeModel } from '../models/ICommitteeModel';
import CommitteeMemberTechnicalStatusText from './Atoms/CommitteeMemberTechnicalStatusText';
import DateToText from './Atoms/DateToText';

interface IMemberListProps {
  membersResult: IMembersModel | null;
  committee: ICommitteeModel | null;
  onReinvite: () => void;
  onActivateCommittee: () => void;
}

export default function MemberList(props: IMemberListProps) {
  return (
		<div className={styles.memberListContainer}>
      
			<div className={[styles.mlArea].join(' ')}>
        <Text className={styles.mlDescription} content="In diesem Bereich können Sie einsehen, ob eingeladene Mitglieder (Gäste) sich am Team anmelden können." />
        
        {props.committee?.technicalStatus === 'InProvisioning' && (
          <Flex>
            <Dialog
              cancelButton="Abbrechen"
              confirmButton="Ja"
              onConfirm={()=>{props.onActivateCommittee();}}
              header="Gremium aktivieren"
              content="Hiermit aktivieren Sie das Gremium, laden alle Gäste ein und versenden an diese eine Einladungsmail. Wollen Sie das tun?"
              trigger={
                <Button
                disabled={!props.membersResult || props?.membersResult?.permissions !== 1}
                content='Gremium aktivieren'
                  primary
                  className={styles.reinviteButton}
                />
              }
            />
          </Flex>
        )}

        {props.committee?.technicalStatus === 'Active' && (
          <Flex>
            <Dialog
              cancelButton="Abbrechen"
              confirmButton="Ja"
              onConfirm={props.onReinvite}
              header="Mitglieder erneut einladen"
              content="Hiermit versenden Sie an alle noch nicht angemeldeten Mitglieder erneut eine Einladungsmail. Wollen Sie das tun?"
              trigger={
                <Button
                  disabled={!props.membersResult || !props.membersResult.members || props.membersResult.permissions < 1 || props.membersResult?.members?.filter(m=>m.technicalStatus === TechnicalMemberStatus.Invited)?.length === 0}
                  content='Nicht angemeldete Mitglieder erneut einladen'
                  primary
                  className={styles.reinviteButton}
                />
              }
            />
          </Flex>
        )}
      </div>
			<Table
        header={
          {items: props?.membersResult?.permissions ? [
            {content:'Name', className:styles.nameCol}, 
            {content:'Status', className:styles.statusCol}, 
            {content:'Zuletzt online', className:styles.loginCol}
          ] : ['Name']}
        }
        rows={props.membersResult?.members?.sort((a, b) => a.displayName > b.displayName ? 1 : -1).map(member => { 
          return { 
            key: member.id, 
            items:
              props?.membersResult?.permissions ?
              [
                {content: member.displayName, className:styles.nameCol},
                {content: (<CommitteeMemberTechnicalStatusText technicalStatus={member.technicalStatus} />), className:styles.statusCol},
                {content: member.isGuest?(<DateToText date={member.lastLogin} />):(<></>), className:styles.loginCol}
              ] :
              [
                member.displayName
              ]
            }
        })} 
      />
		</div>
  );
}