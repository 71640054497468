import React from 'react';
import * as msTeams from '@microsoft/teams-js';

interface IConfigureProps{
  displayName: string;
  url: string;
}

export default function Configure(props: IConfigureProps) {
  msTeams.settings.registerOnSaveHandler(saveEvent => {
    msTeams.settings.setSettings({
      contentUrl: `${window.location.origin}${props.url}`,
      entityId: window.location.origin,
      suggestedDisplayName: props.displayName
    });

    saveEvent.notifySuccess();
  });
  msTeams.settings.setValidityState(true);

  return (
    <>
      <div>Hiermit fügen Sie diese Komponente für das Gremienmanagement Ihrem Team hinzu.</div>
    </>
  );
}