import { xhr } from "../helpers/xhr";
import { IAppointmentModel } from "../models/IAppointmentModel";

export class AppointmentService {
	public static async getAppointments(committeeId: string): Promise<IAppointmentModel[]> {
		const response = await xhr(`committees/${committeeId}/appointments`);
		return AppointmentService.parseArray(response.data);
	}

	private static parse(data: any): IAppointmentModel {
		const date = data['date'] ? new Date(data['date']) : undefined ;
		return {
			id: data['id'],
			title: data['title'],
			date: date,
			joinUrl: data['joinUrl'],
			webLink: data['webLink'],	
		} as IAppointmentModel;
	}

	private static parseArray(data: any[]): IAppointmentModel[] {
		if (data && data.length) {
			return data.map(AppointmentService.parse);
		}
		return [];
	}
}