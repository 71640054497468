import { ThemePrepared, teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-northstar';
import { TeamsThemes } from '../constants';

export default class TeamsThemeHelper {
    private static themeString: string = 'default';

    public static getTheme(themeStr: string | undefined): ThemePrepared {
        themeStr = themeStr || '';

        switch (themeStr) {
            case TeamsThemes.dark:
                return teamsDarkTheme;
            case TeamsThemes.contrast:
                return teamsHighContrastTheme;
            case TeamsThemes.default:
            default:
                return teamsTheme;
        }
    }

    public static getCurrentTheme(): ThemePrepared {
        return this.getTheme(this.getThemeString());
    }

    public static setThemeString(themeStr: string) {
        this.themeString = themeStr;
    }

    public static getThemeString() {
        return this.themeString;
    }
}