import { Divider, Avatar, EmailIcon, Flex, Header, PhoneIcon, Text, Button, Skeleton } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';
import { ICommitteeModel } from '../../models/ICommitteeModel';
import { IContactModel } from '../../models/IContactModel';
import { IMemberModel } from '../../models/IMemberModel';
import { CommitteeService } from '../../services/CommitteeService';


interface IContactProps {
	committee: ICommitteeModel|null;
	member: IMemberModel|null;
}


export const Contact = (props: IContactProps) => {
	const [contact, setContact] = useState<IContactModel|null>(null);

	useEffect(() => {
		if(props.committee?.id && props.member?.userId) {
			CommitteeService.getCommitteeContact(props.committee?.id, props.member?.userId).then((contact) => {
				setContact(contact);
			})
		}
	}, [props.committee?.id, props.member?.userId]);

	return (
		<>
			{!contact && 
				<Skeleton animation="wave">
            		<Skeleton.Shape width="255px" height="30px" />
					<Flex gap="gap.medium" styles={{'padding-top':'18px'}}>
						<Skeleton.Shape round width="96px" height="96px" />
						<Flex column gap="gap.small">
							<Skeleton.Shape width="60px" height="20px" styles={{'margin-bottom':'10px'}} />
							<Skeleton.Shape width="100px" height="20px" styles={{'margin-bottom':'10px'}} />
							<Skeleton.Shape width="80px" height="20px" styles={{'margin-bottom':'10px'}} />
							<Divider />
							<Skeleton.Shape width="120px" height="20px" styles={{'margin-bottom':'10px'}} />
							<Skeleton.Shape width="115px" height="20px" styles={{'margin-bottom':'10px'}} />
							<Skeleton.Shape width="230px" height="20px" />
						</Flex>
					</Flex>
				</Skeleton>
			}
			{contact &&
				<>
					<Header as="h2" content={contact.displayName} styles={{'margin-top':0}} />
					<Flex gap="gap.medium">
						<Avatar size='largest' image={contact.photo?`data:image/png;base64, ${contact.photo}`:''} name={`${contact.givenName} ${contact.surname}`} />
						<Flex column gap="gap.small">
							<Flex gap="gap.medium"><Text content={contact.division || '-'} /></Flex>
							<Flex gap="gap.medium"><Text content={contact.department || '-'} /></Flex>
							<Flex gap="gap.medium"><Text content={contact.jobTitle || '-'} /></Flex>
							<Divider />
							<Flex gap="gap.medium"><PhoneIcon outline /><Text content={contact.phone||'-'}/></Flex>
							<Flex gap="gap.medium"><PhoneIcon outline /><Text content={contact.mobile||'-'}/></Flex>
							<Flex gap="gap.medium"><Button styles={{'padding-left':0}} icon={<EmailIcon outline />} text content={contact.mail||'-'} onClick={()=>{window.open(`mailto:${contact.mail}`)}} /></Flex>
						</Flex>
					</Flex>
				</>
			}
		</>
	);
}