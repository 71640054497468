import { Text } from '@fluentui/react-northstar';
import styles from './CommitteeStatus.module.scss';

interface CommitteeStatusProps {
    status?: string;
    mode?: string;
}

export default function CommitteeStatus(props: CommitteeStatusProps) {
    return (
        <div className={styles.committeeStatus}>
            {props.status === 'Active' && <Text className={styles.activeStatus} content='aktiv' />}
            {props.status === 'InProvisioning' && <Text className={styles.archivedStatus} content='in Arbeit' />}
            {props.status === 'Archived' && <Text className={styles.archivedStatus} content='archiviert' />}
        </div>
    );
}