import { Dialog, Flex, Text, TextArea } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { ICommitteeModel } from '../../models/ICommitteeModel';
import { MemberService } from '../../services/MemberService';
import { SelfServiceService } from '../../services/SelfServiceService';

interface IJoinTeamDialogProps {
	joinTeamUrl?: string;
	committee: ICommitteeModel|null;
	open: boolean;
	onClose: ()=>void;
}

export const JoinTeamDialog = (props: IJoinTeamDialogProps) => {
	const [comments, setComments] = useState('');

	return (
		<Dialog
			cancelButton="Abbrechen"
			confirmButton="Absenden"
			onConfirm={() => {
				SelfServiceService.requestTeamJoin({
					committee:props.committee,
					comments,
					requester:MemberService.getCurrentMember().upn
				});
				props.onClose();
			}}
			onCancel={()=>props.onClose()}
			header="Team beitreten"
			content={
                <Flex column hAlign="start" vAlign="start" gap="gap.small">
					<Text content="Bemerkungen (optional):" />
					<TextArea fluid variables={{"height": "150px"}} onChange={(ev, textAreaProps)=>setComments(textAreaProps?.value || '')} />
                </Flex>
			}
			open={props.open && props.committee!==null}
		/>
	)
}