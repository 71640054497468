import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { routes as routeDefinitions } from '../config';
import { IRouteDefinition } from '../models/IRouteDefinition';
import styles from './RouterHelper.module.scss';

export default class RouterHelper {
  public static Routes(): JSX.Element {
    return RouterHelper.RenderRoutes(routeDefinitions);
  }

  private static RenderRoutes(routes: IRouteDefinition[]): JSX.Element {
    return (
      <div className={styles.zgAppContainer}>
        <Router>
          <Switch>
            {routes.map(route =>
              <Route key={route.route} path={route.route} exact={route.route === '/'}>
                {route.component}
              </Route>
            )}
          </Switch>
        </Router>
      </div>
    );
  }
}