import React, { useContext, useEffect, useState } from 'react';

import MemberList from '../components/MemberList';
import { MemberService } from '../services/MemberService';
import { IMembersModel } from '../models/IMemberModel';
import { globalContext } from '../state/store';
import { Loader } from '@fluentui/react-northstar';
import { ICommitteeModel } from '../models/ICommitteeModel';
import { CommitteeService } from '../services/CommitteeService';


export default function MemberListPage() {
  const {globalState, } = useContext(globalContext);

  useEffect(()=>{
    const reloadData = async () => {
      var newMembersResult = await MemberService.getMembers(globalState.groupId, true);
      setMembersResult(newMembersResult);

      var newCommittee = await CommitteeService.getCommittee(globalState.groupId);
      setCommittee(newCommittee);
    };

    if(globalState.groupId !== '') reloadData();
  }, [globalState.groupId]);

	const [membersResult, setMembersResult] = useState<IMembersModel | null>(null);
	const [committee, setCommittee] = useState<ICommitteeModel | null>(null);

  return (
    <>
      {!membersResult ?
        <Loader label="Bitte warten..." />
      :
        <MemberList 
          membersResult={membersResult}
          committee={committee}
          onReinvite={() => {MemberService.reinviteMembers(globalState.groupId)}}
          onActivateCommittee={() => {
            if(committee !== null) {
              CommitteeService.activateCommittee(committee);
              setCommittee({...committee, technicalStatus: 'Active'});
            }
          }}
        />
      }
    </>
  );
}