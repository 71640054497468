export interface IMembersModel {
	members: IMemberModel[];
	permissions: number;
}

export interface IMemberModel {
	id: string;
	eMailAddress: string;
	title?: string;
	firstName?: string;
	familyName?: string;
	company?: string;
	division?: string;
	role?: string;
	lastLogin?: Date;
	displayName: string;
	technicalStatus?: TechnicalMemberStatus;
	isGuest?: boolean;
	userId: string;
}

export enum TechnicalMemberStatus
{
	None,
	Invited,
	Active,
	Error
}