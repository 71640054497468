import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { TokenService } from '../services/TokenService';

export interface IResponse {
	data: any;
	contentType: string;
	headers: any;
}

export interface IRequestConfig extends AxiosRequestConfig {
	onError?: (response: AxiosError) => Promise<IResponse>;
}

async function xhr(url: string, opts?: IRequestConfig): Promise<IResponse> {
	const authToken = await TokenService.getInstance().getAuthenticationToken();

	opts = { method: 'GET', ...opts, withCredentials: true };
	opts.headers = {
		'content-type': 'application/json',
		'x-id-token': authToken,
		...opts.headers,
	} as Record<string, string>;

	//disable cache for ie
	if ((window.document as any).documentMode && opts.method!.toLocaleLowerCase() === 'get') {
		opts.params = { _r: Math.random().toString() };
	}

	console.log(opts);

	try {
		if(url.startsWith('/')) url = url.substr(1);
		if(!url.startsWith('https://')) url = `/api/v1/${url}`;
		const response = await axios(url, opts);
		const contentType = (response.headers['content-type'] || '').toLowerCase();
		return { data: response.data, contentType, headers: response.headers };
	} catch (e) {
		const ex = e as AxiosError;
		if (opts.onError) {
			return opts.onError(ex);
		} else {
			return Promise.reject(e);
		}
	}
}

export { xhr };