import { initialState } from './store';
import { ActionType, IGlobalStateInterface } from './types';

const Reducer = (state: IGlobalStateInterface, action: ActionType): any => {
  switch (action.type) {
    case 'SET_GROUPID':
      return {
        ...state,
        groupId: action.payload,
      };
    case 'PURGE_STATE':
      return initialState;
    default:
      return state;
  }
};

export default Reducer;