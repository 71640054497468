import React, { useContext, useEffect, useState } from 'react';

import { CommitteeService } from '../services/CommitteeService';
import { ICommitteeModel } from '../models/ICommitteeModel';
import { globalContext } from '../state/store';
import { CommitteeList } from '../components/CommitteeList';
import { ISelfServiceConfig } from '../models/ISelfServiceConfig';
import { SelfServiceService } from '../services/SelfServiceService';
import { MemberService } from '../services/MemberService';


export default function ComitteeListPage() {
  const {globalState, } = useContext(globalContext);

  const [committees, setCommittees] = useState<ICommitteeModel[] | null>(null);
  const [selfServiceConfig, setSelfServiceConfig] = useState<ISelfServiceConfig | null>(null);

  useEffect(()=>{
    const reloadStuff = async () => {
      var newCommittees = await CommitteeService.getCommittees();
      setCommittees(newCommittees);

      var newSelfServiceConfig = await SelfServiceService.getSelfServiceConfig();
      setSelfServiceConfig(newSelfServiceConfig);


    };
    if(globalState.groupId !== '') reloadStuff();
  }, [globalState.groupId]);


  return (
    <>
      <CommitteeList committees={committees} config={selfServiceConfig} currentUserId={MemberService.getCurrentMember()?.id} />
    </>
  );
}