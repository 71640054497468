import { Text } from '@fluentui/react-northstar';
import { TechnicalMemberStatus } from '../../models/IMemberModel';
import styles from './CommitteeMemberTechnicalStatusText.module.scss';

interface CommitteeMemberTechnicalStatusTextProps {
    technicalStatus?: TechnicalMemberStatus;
}

export default function CommitteeMemberTechnicalStatusText(props: CommitteeMemberTechnicalStatusTextProps) {

    function getTechnicalStyle() : string {
        switch(props.technicalStatus) {
            case TechnicalMemberStatus.Active :
                return styles.active;
            case TechnicalMemberStatus.Error : 
                return styles.error;
            case TechnicalMemberStatus.Invited : 
                return styles.invited;
            default :
                return styles.none; 
        }
    }

    function getTechnicalText() : string {
        debugger;
        switch(props.technicalStatus) {
            case TechnicalMemberStatus.Active :
                return "Angemeldet";
            case TechnicalMemberStatus.Error : 
                return "Es ist ein Fehler aufgetreten";
            case TechnicalMemberStatus.Invited : 
                return "Eingeladen";
            default :
                return "Unbekannt"; 
        }
    }

    return (
        <>
            <Text className={[styles.technicalStatusText, getTechnicalStyle()].join(' ')} content={getTechnicalText()} />
        </>
    );
}