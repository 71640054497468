import React, { useEffect, useState } from 'react';

import { CommitteeService } from '../services/CommitteeService';
import { ICommitteeModel } from '../models/ICommitteeModel';

import { IMemberModel } from '../models/IMemberModel';
import { IAppointmentModel } from '../models/IAppointmentModel';
import { MemberService } from '../services/MemberService';
import { AppointmentService } from '../services/AppointmentService';

import CommitteeOverview from '../components/CommitteeOverview';

export interface CommitteeOverviewHubProps {
    groupId: string;
}

export default function CommitteeOverviewHub(props: CommitteeOverviewHubProps) {
  const [committee, setCommittee] = useState<ICommitteeModel | null>(null);
  const [members, setMembers] = useState<IMemberModel[] | null>(null);
  const [appointments, setAppointments] = useState<IAppointmentModel[] | null>(null);
  const [committeeDatasheetLinks, setCommitteeDatasheetLinks] = useState<string[] | null>(null);

  useEffect(()=>{
    const reloadCommittee = async () => {
      var newCommittee = await CommitteeService.getCommittee(props.groupId);
      setCommittee(newCommittee);
    };
  
    const reloadMembers = async() => {
      var newMembersResult = await MemberService.getMembers(props.groupId);
      setMembers(newMembersResult.members);
    };
  
    const reloadAppointments = async() => {
      var newAppointments = await AppointmentService.getAppointments(props.groupId);
      setAppointments(newAppointments);
    }

    const reloadCommitteeDatasheetLinks = async() => {
      var newDatasheetLinks = await CommitteeService.getCommitteeDatasheetLinks(props.groupId);
      setCommitteeDatasheetLinks(newDatasheetLinks);
    }

    if(props.groupId !== ''){
      reloadCommittee();
      reloadMembers();
      reloadAppointments();
      reloadCommitteeDatasheetLinks();
    }
  }, [props.groupId]);

  return (
      <CommitteeOverview committee={committee} committeeAppointments={appointments} committeeDatasheetLinks={committeeDatasheetLinks} committeeMembers={members} />
  )
}