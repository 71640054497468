import React, { useContext, useState } from 'react';

import { Provider as NorthstarThemeProvider } from '@fluentui/react-northstar';

import * as msTeams from '@microsoft/teams-js';
import { Providers, ProviderState, SimpleProvider, TeamsHelper } from '@microsoft/mgt-react';

import { globalContext, GlobalStore } from './state/store';
import { RouterHelper, TeamsThemeHelper } from './helpers';
import { TokenService } from './services/TokenService';
import { MemberService } from './services/MemberService';
import { initializeIcons } from '@uifabric/icons';

export interface Props {
}

const InnerApp = (props: Props) => {
  const {globalState, dispatch} = useContext(globalContext);
  
  const [theme, setTheme] = useState(TeamsThemeHelper.getTheme('default'));
  const [themeName, setThemeName] = useState('default');

  const updateTheme = (themeString: string | undefined): void => {
    setTheme(TeamsThemeHelper.getTheme(themeString));
    setThemeName(themeString ? themeString : 'default');
    TeamsThemeHelper.setThemeString(themeName);
  }

  TeamsHelper.microsoftTeamsLib = msTeams;
  Providers.globalProvider = new SimpleProvider(
    (scopes: string[])=>{
      return TokenService.getInstance().getAccessToken(scopes);
    },
    () => {
      Providers.globalProvider.setState(ProviderState.SignedIn);
      return Promise.resolve();
    },
    () => {
      Providers.globalProvider.setState(ProviderState.SignedOut);
      return Promise.resolve();
    }
  );
  
  Providers.globalProvider.login && Providers.globalProvider.login();
  
  msTeams.initialize();
  msTeams.registerOnThemeChangeHandler(updateTheme.bind(this));
  msTeams.getContext(context => { 
    updateTheme(context.theme);
    if(globalState.groupId !== context.groupId) dispatch({type: 'SET_GROUPID', payload: context.groupId});
    if(context.userObjectId) {
      MemberService.setCurrentMember(context.userObjectId,context.userPrincipalName);
    }
  });

  initializeIcons();

  return (
    <NorthstarThemeProvider theme={theme} >
      <div data-theme={themeName}>
        <RouterHelper.Routes />
      </div>
    </NorthstarThemeProvider>
  )
}

export const App = (props: Props) => {
  return (
    <GlobalStore>
      <InnerApp />
    </GlobalStore>
  )
}