import React, { useContext } from 'react';
import CommitteeOverviewHub from '../components/CommitteeOverviewHub';
import { globalContext } from '../state/store';


export default function ComitteeDetailsPage() {
  const {globalState, } = useContext(globalContext);

  return (
    <>
      <CommitteeOverviewHub groupId={globalState.groupId} />
    </>
  );
}