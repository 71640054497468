import { Button, FilesPdfIcon, WordIcon } from '@fluentui/react-northstar';
import React from 'react';

import { IAppointmentModel } from '../models/IAppointmentModel';
import { ICommitteeModel } from '../models/ICommitteeModel';
import { IMemberModel } from '../models/IMemberModel';
import AppointmentCard from './AppointmentCard';
import { CommitteeMemberCard } from './CommitteeMemberCard';

import styles from './CommitteeOverview.module.scss';

interface ICommitteeOverviewProps {
    committee: ICommitteeModel | null;
	committeeMembers: IMemberModel[] | null;
	committeeAppointments: IAppointmentModel[] | null;
	committeeDatasheetLinks: string[] | null;
}

export default function CommitteeOverview(props: ICommitteeOverviewProps) {
	const docxLink = props.committeeDatasheetLinks?.length === 2 ? props.committeeDatasheetLinks[0] : '';
	const pdfLink = props.committeeDatasheetLinks?.length === 2 ? props.committeeDatasheetLinks[1] : '';

	const newlineToBreak = function(text: string|undefined){
		if(!text) return '';
		return text.split('\r\n').map((item, key) => {
			return <span>{item}<br/></span>
		})
	};

	const delegates = props.committeeMembers?.filter(committeeMember => committeeMember.role === 'Stellvertretung (1)' || committeeMember.role === 'Stellvertretung (2)');

	return (
		<div className={styles.committeeOverviewContainer}>
			<div className={[styles.coArea, styles.coAreaGrid].join(' ')}>
				<div className={[styles.coAreaGridItem, styles.coAreaFlex].join(' ')}>
					{pdfLink&&(<Button icon={<FilesPdfIcon />} text content="PDF Datenblatt abrufen" className={styles.coHeaderLink} onClick={(ev) => window.open(pdfLink)} />)}
					{!pdfLink&&(<span>(PDF Datenblatt wird generiert)</span>)}
					{docxLink&&(<Button icon={<WordIcon />} text content="Datenblatt nach Word exportieren" className={styles.coHeaderLink} onClick={(ev) => window.open(docxLink)} />)}
					{!docxLink&&(<span>(Word Datenblatt wird generiert)</span>)}
				</div>
			</div>
			<div>
				<div className={[styles.coAreaGridItem].join(' ')}>
					<h1 className={styles.coTitle}>{props.committee?.name}</h1>
				</div>
			</div>
			<div className={[styles.coArea].join(' ')}>
				<div className={styles.coAreaTitle}>ZVEI Organisationseinheit:</div>
				<div>{props.committee?.organisationalUnit}</div>
			</div>
			<div className={[styles.coArea].join(' ')}>
				<div className={styles.coAreaTitle}>Kurzbeschreibung:</div>
				<div className={styles.coDescription}>{props.committee?.description}</div>
			</div>
			<div className={[styles.coArea, styles.coAreaGrid].join(' ')}>
				<div className={[styles.coAreaGridItem].join(' ')}>
					<div className={[styles.coAreaTitle, styles.coatExtraSpace].join(' ')}>Kontakt im ZVEI:</div>
					<div className={[styles.coAreaFlex, styles.coAreaFlexWrap].join(' ')}>
						{props.committeeMembers?.filter(committeeMember => committeeMember.role === '[GRE] ZVEI-Verantwortliche(r)').map((committeeMember) => {
							return(
								<div className={styles.coatCursorPointer} onClick={()=>{window.open(`mailto:${committeeMember.eMailAddress}`)}}>
									<CommitteeMemberCard committeeMember={committeeMember} disablePersonCard={true} />
								</div>
							);
						})}
					</div>
				</div>
				<div className={[styles.coAreaGridItem].join(' ')}>
					<div className={[styles.coAreaTitle, styles.coatExtraSpace].join(' ')}>Vorsitz:</div>
					<div className={[styles.coAreaFlex, styles.coAreaFlexWrap].join(' ')}>
						{props.committeeMembers?.filter(committeeMember => committeeMember.role === '[GRE] Vorsitz').map((committeeMember) => {
							return(
								<CommitteeMemberCard committeeMember={committeeMember} disablePersonCard={true} />
							);
						})}
					</div>
				</div>
			</div>
			{delegates && delegates.length > 0 && (
				<div className={[styles.coArea].join(' ')}>
					<div className={[styles.coAreaTitle, styles.coatExtraSpace].join(' ')}>Stellvertetung:</div>
					<div>
						<div className={[styles.coAreaFlex, styles.coAreaFlexWrap].join(' ')}>
							{delegates?.map((committeeMember) => {
								return(
									<CommitteeMemberCard committeeMember={committeeMember} disablePersonCard={true} />
								);
							})}
						</div>
					</div>
				</div>
			)}
			<div className={[styles.coArea].join(' ')}>
				<div className={styles.coAreaTitle}>Folgende Unternehmen bzw. Institutionen sind im Gremium vertreten:</div>
				<div>{props.committee?.representingCompanies}</div>
			</div>
			<div className={[styles.coArea, styles.coAreaGrid].join(' ')}>
				<div className={[styles.coAreaGridItem].join(' ')}>
					<div className={styles.coAreaTitle}>Themen:</div>
					<div>{newlineToBreak(props.committee?.topics)}</div>
				</div>
				<div className={[styles.coAreaGridItem].join(' ')}>
					<div className={styles.coAreaTitle}>Ziele und Aktivitäten:</div>
					<div>{newlineToBreak(props.committee?.objectives)}</div>
				</div>
			</div>
			{props.committeeAppointments && props.committeeAppointments.length > 0 && (
				<div className={[styles.coArea].join(' ')}>
					<div className={[styles.coAreaTitle, styles.coatExtraSpace].join(' ')}>Termine und Veranstaltungen:</div>
					<div className={[styles.coAreaFlex, styles.coAreaFlexWrap].join(' ')}>
						{props.committeeAppointments?.map((committeeAppointment) => {
							return(
								<AppointmentCard appointment={committeeAppointment}></AppointmentCard>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}