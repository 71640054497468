import { Text } from '@fluentui/react-northstar';
import { formatDistance } from 'date-fns/esm';
import { de } from 'date-fns/locale';

interface DateToTextProps {
    date?: Date;
}

export default function DateToText(props: DateToTextProps) {
    function getDateText() {
        if(props.date) {
            return formatDistance(new Date(props.date), new Date(), {locale: de});
        }
    }

    return (
        <>
            <Text content={getDateText()} />
        </>
    );
}