import { Card, Flex, Text } from '@fluentui/react-northstar';
import { IAppointmentModel } from '../models/IAppointmentModel';
import { format } from "date-fns";
import { de } from 'date-fns/locale';
import styles from './AppointmentCard.module.scss';

interface IAppointmentCardProps {
    appointment: IAppointmentModel;
}

export default function AppointmentCard(props: IAppointmentCardProps) {
    return (
        <Card className={styles.appointmentCardContainer}>
            <Card.Header fitted>
                <div className={styles.acDateBig}>
                    <Flex column hAlign="center" vAlign="start" gap="gap.small">
                        <Text content={format(props.appointment.date, "LLL" , {locale: de})} weight="bold" />
                        <Text content={format(props.appointment.date, "dd" , {locale: de})} weight="bold" size="largest" />
                    </Flex>
                </div>
                <div className={styles.acContent}>
                    <Flex column gap="gap.small">
                        <Text content={props.appointment.title} />
                        <Text content={format(props.appointment.date, "EEEEEE, dd. MMM HH:mm" , {locale: de})} />
                    </Flex>
                </div>
            </Card.Header>
        </Card>    
    );
}