import { xhr } from "../helpers/xhr";
import { ISelfServiceConfig } from "../models/ISelfServiceConfig";

export class SelfServiceService {
	public static async getSelfServiceConfig(): Promise<ISelfServiceConfig> {
		const response = await xhr(`selfserviceconfig`);
		return {
			joinTeamLink: response.data['joinTeamLink'],
			requestCommitteeLink: response.data['requestCommitteeLink'],
		
		} as ISelfServiceConfig;
	}

	public static async requestTeamJoin(request:any): Promise<any> {
		return await xhr(`committees/join`, {method:`POST`, data: request})
	}
}