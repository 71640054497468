import React from 'react';
import { CommitteeDetailsPage, CommitteeListPage, Configure, MemberListPage } from '../pages';
import { IRouteDefinition } from '../models/IRouteDefinition';

const routes: IRouteDefinition[] = [
    {
        route: '/configureCommitteeDetails',
        component: <Configure displayName='Gremiendetails' url='/committeeDetails' />,
    },
    {
        route: '/committeeDetails',
        component: <CommitteeDetailsPage />,
    },
    {
        route: '/configureCommitteeList',
        component: <Configure displayName='Gremienliste' url='/committeeList' />,
    },
    {
        route: '/committeeList',
        component: <CommitteeListPage />,
    },
    {
        route: '/configureMemberList',
        component: <Configure displayName='Mitgliederliste' url='/memberList' />,
    },
    {
        route: '/memberList',
        component: <MemberListPage />,
    },
];

export default routes;